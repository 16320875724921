import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Content, Exit, Footer, Header, Stepper } from './components';
import CriticalError from './components/errors/CriticalError';
import SimpleError from './components/errors/SimpleError';
import ValidationError from './components/errors/ValidationError';
import { Loader } from './components/UI/loader/Loader';
import { useAppDispatch } from './hooks/useAppDispatch';
import { useAppSelector } from './hooks/useAppSelector';
import { WidgetLayout } from './layouts'; 
import { ICartItem } from './models/Cart';
import { AppStatuses } from './shared/enums/AppStatuses';
import { isValidationResponseError } from './shared/types/API/ResponseSuccessType';
import { setAppStatus, setCart, setOrderNumber, setRetailPointId } from './store/app/AppSlice';
import { removeToast } from './store/toast/ToastSlice';
import { queryStringToObject } from './utils/queryStringToObject';

// {
//   "retailPointId": "523a63b3-8a03-4af3-825f-51d7164a1e63",
//   "orderNumber":123212323,
//   "cart": [
//     {
//       "id": "adsfasfadsfasdf",
//       "categoryId": "c0c6ab14-fff1-46c0-92af-ae3a50df8090",
//       "brand": "APPLE",
//       "model": "Iphone 14 pro max",
//       "sku": 123412313,
//       "price":123123
//     }
//   ]
// }
//http://localhost:3002/?retailPointId=523a63b3-8a03-4af3-825f-51d7164a1e63&orderNumber=123212323&cart[0][id]=1231231313asfsafadsf&cart[0][categoryId]=c0c6ab14-fff1-46c0-92af-ae3a50df8090&cart[0][brand]=APPLE&cart[0][model]=Iphone%2014%20pro%20max&cart[0][sku]=123412313&cart[0][price]=123123&cart[1][id]=adsfasfadsfasdf&cart[1][categoryId]=c0c6ab14-fff1-46c0-92af-ae3a50df8090&cart[1][brand]=APPLE&cart[1][model]=Iphone%2013%20pro%20max&cart[1][sku]=21231223&cart[1][price]=102030

export type EntryPointType = {
    orderNumber: string;
    retailPointId: string;
    cart: ICartItem[];
    apiKey?: string;
};

type AppProps = {
    entryData: EntryPointType;
};

const App = ({ entryData }: AppProps) => {
    const { status, order } = useAppSelector((state) => state.applicationStore);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isCriticalError, setIsCriticalError] = useState(false);
    const { toasts } = useAppSelector((state) => state.toastStore);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const data = entryData
            ? entryData
            : queryStringToObject<EntryPointType>(window.location.search.replace('?', ''));
        if (data) {
            dispatch(setCart([...data.cart]));
            dispatch(setOrderNumber(data.orderNumber));
            dispatch(setRetailPointId(data.retailPointId));
            setIsLoading(false);
            setIsCriticalError(false);
        } else {
            setIsLoading(false);
            setIsCriticalError(true);
        }
    }, []);
    useEffect(() => {
        if (toasts.length) {
            const lastError = toasts[toasts.length - 1];
            let component;
            if (isValidationResponseError(lastError)) {
                component = (
                    <ValidationError
                        type={lastError.type}
                        violations={lastError.violations}
                        detail={lastError.detail}
                        title={lastError.title}
                    />
                );
            } else {
                component = (
                    <SimpleError
                        status={lastError.status}
                        type={lastError.type}
                        detail={lastError.detail}
                        title={lastError.title}
                    />
                );
            }
            dispatch(removeToast(lastError.id));
            toast.error(component);
        }
    }, [toasts]);
    const onClickCloseHandler = useCallback(() => {
        dispatch(setAppStatus(AppStatuses.EXIT));
    }, []);
    const Data = useMemo(() => {
        return (
            <div className={'relative h-full pt-[40px]'}>
                <Stepper />
                <Content />
                {status === AppStatuses.EXIT ? <Exit /> : null}
            </div>
        );
    }, [status]);

    return (
        <section className="w-full flex justify-center">
            <WidgetLayout
                Header={
                    <Header
                        onClickCloseHandler={onClickCloseHandler}
                        amount={order.cart.reduce((acc, cartItem) => {
                            return acc + (cartItem?.price || 0);
                        }, 0)}
                        orderNumber={order.orderNumber}
                    />
                }
                Footer={<Footer />}>
                {isLoading ? (
                    <div
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                        }}>
                        <Loader />
                    </div>
                ) : isCriticalError && status !== AppStatuses.EXIT ? (
                    <CriticalError />
                ) : (
                    Data
                )}
                <ToastContainer
                    className={'!absolute !w-full !max-w-[90%] '}
                    progressClassName={'!bg-mainColor'}
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                />
            </WidgetLayout>
        </section>
    );
};
export default App;
