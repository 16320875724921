import React, { useEffect } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { setVerifyData, verifying } from '../../store/client/ClientSlice';
import { setActiveStepTitle } from '../../store/StepperSlice';
import classes from './confirmationCode.module.css';
import { phoneFormatter } from '../../shared/utils/phoneFormatter';
import { Code } from '../UI/code/Code';
import { PhoneVerificationFooter } from './PhoneVerificationFooter';

type Props = {
    onSuccessVerifying: (token: string) => void;
    onChangeClientDataClickHandler: () => void;
};
const PhoneVerification = ({ onChangeClientDataClickHandler, onSuccessVerifying }: Props) => {
    const { phone, verify, id, isLoading } = useAppSelector((state) => {
        const { clientData, clientVerification, isLoading } = state.clientStore;
        return {
            phone: clientData.phone,
            verify: clientVerification.verify,
            id: clientVerification.sendCode.id,
            isLoading,
        };
    });
    useEffect(() => {
        dispatch(setActiveStepTitle('Подтвердите номер телефона'));
    }, []);
    const dispatch = useAppDispatch();
    const onChangeCodeHandler = (code: string) => {
        if (code.length === 4) {
            dispatch(verifying({ code: code, verifyMessageId: id }))
                .unwrap()
                .then(({ token }) => {
                    if (token) onSuccessVerifying(token);
                });
        }
    };
    return (
        <div>
            <p className={classes.confirmation_text}>
                На номер телефона
                <span className={'font-[600] select-text'}>{phoneFormatter(phone)}</span> отправлено
                СМС сообщение с кодом подтверждения. Введите код.
            </p>
            <div className={classes.confirmation_content}>
                <Code
                    onFocus={() => {
                        if (verify.status === false) {
                            dispatch(setVerifyData({ ...verify, status: null }));
                        }
                    }}
                    withError={verify.status === false}
                    onChange={onChangeCodeHandler}
                    disabled={verify.hasAttempts === false || isLoading}
                />
                <PhoneVerificationFooter isLoading={isLoading} isDone={!verify.hasAttempts} />
                <span
                    className={
                        'text-primaryGrey cursor-pointer hover:text-mainColor ease-in-out duration-300'
                    }
                    onClick={() => onChangeClientDataClickHandler()}>
                    Изменить данные
                </span>
            </div>
        </div>
    );
};
export default PhoneVerification;
