import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from '../UI/input/Input';
import { iinValidator } from '../../validators/iinValidator';
import { emailValidator } from '../../validators/emailValidator';
import { phoneValidator } from '../../validators/phoneValidator';
import Button from '../UI/button/Button';
import { phoneNormilize } from '../../utils/phoneNormilize';
import { fioValidator } from '../../validators/fioValidator';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Loader } from '../UI/loader/Loader';
import classes from './clientData.module.css';
import { useAppSelector } from '../../hooks/useAppSelector';
import { IClientData } from '../../models/ClientData';
import { setActiveStep } from '../../store/StepperSlice';
import { saveTempClientData } from '../../store/client/ClientSlice';

interface ClientDataProps {
    onConfirmUserData: (clientData: IClientData) => void;
    isPhoneConfirmed: boolean;
    nextButtonText: string;
    isLoading?: boolean;
}
enum FieldsId {
    iin = 'iin',
    fio = 'fio',
    phone = 'phone',
    email = 'email',
}
type DataState = {
    values: {
        [fieldId in FieldsId]: string;
    };
    errors: {
        [fieldId in FieldsId]: string | null;
    };
};
const fields = [
    {
        id: FieldsId.iin,
        name: FieldsId.iin,
        label: 'ИИН',
        mask: '999999999999',
        validator: iinValidator,
    },
    {
        id: FieldsId.fio,
        name: FieldsId.fio,
        label: 'Фамилия Имя Отчество',
        validator: fioValidator,
    },
    {
        id: FieldsId.email,
        name: FieldsId.email,
        label: 'Электронная почта',
        validator: emailValidator,
    },
    {
        id: FieldsId.phone,
        name: FieldsId.phone,
        label: 'Телефон',
        mask: '+7 (999) 999-99-99',
        validator: phoneValidator,
        normilize: phoneNormilize,
    },
];
const ClientData = ({
    onConfirmUserData,
    isPhoneConfirmed,
    nextButtonText,
    isLoading,
}: ClientDataProps) => {
    const dispatch = useAppDispatch();
    const clientData = useAppSelector((state) => state.clientStore.clientData);
    const [data, setData] = useState<DataState>({
        values: {
            iin: clientData.iin || '',
            fio: clientData.fio || '',
            phone: clientData.phone || '',
            email: clientData.email || '',
        },
        errors: {
            iin: null,
            fio: null,
            phone: null,
            email: null,
        },
    });
    useEffect(() => {
        fields.forEach(({ validator, id }) => {
            const isValid = validator(data.values[id]);
            setData((prevState) => {
                return {
                    values: prevState.values,
                    errors: {
                        ...prevState.errors,
                        [id]: isValid.errorMessage,
                    },
                };
            });
        });
    }, []);
    const onChangeData = (event: React.ChangeEvent<HTMLInputElement>, id: FieldsId) => {
        let value = event.target.value;
        const currentField = fields.find((field) => field.id === id);
        if (currentField?.normilize) value = currentField.normilize(value);
        if (data.values[id] !== value) {
            let validationMessage: string | null = null;
            if (currentField?.validator) {
                const validation = currentField.validator(value.trim());
                validationMessage = validation.errorMessage ?? '';
            }
            setData((prevState) => {
                return {
                    ...prevState,
                    values: {
                        ...prevState.values,
                        [id]: value,
                    },
                    errors: {
                        ...prevState.errors,
                        [id]: validationMessage,
                    },
                };
            });
        }
    };
    const onBlurHandler = (fieldId: FieldsId) => {
        const currentField = fields.find((field) => field.id === fieldId);
        if (currentField) {
            if (currentField.validator) {
                const validation = currentField.validator(data.values[fieldId].trim());
                if (!validation.errorMessage) {
                    dispatch(saveTempClientData({ data: data.values[fieldId], fieldId: fieldId }));
                }
                setData((prevState) => {
                    return {
                        ...prevState,
                        errors: {
                            ...prevState.errors,
                            [fieldId]: validation.errorMessage,
                        },
                    };
                });
            }
        }
    };
    const isDisabled = useMemo(() => {
        let isDisabled = false;
        const keys = Object.keys(data.values) as FieldsId[];
        for (let i = 0; i < keys.length; i++) {
            const currentKey = keys[i];
            const currentValue = data.values[currentKey];
            const currentError = data.errors[currentKey];
            if (currentError || currentError === null || !currentValue || isLoading) {
                isDisabled = true;
                break;
            }
        }
        return isDisabled;
    }, [data, isLoading]);

    const onSubmit = () => {
        const [lastName, firstName, ...middleName] = data.values.fio.split(' ');
        const client: IClientData = {
            fio: data.values.fio,
            email: data.values.email,
            iin: data.values.iin,
            phone: data.values.phone,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName.join('-') || null,
        };
        onConfirmUserData(client);
    };
    const onPrevClickHandler = useCallback(() => {
        dispatch(setActiveStep(1));
    }, []);

    return (
        <div>
            <div className={'flex flex-wrap'}>
                {fields.map((field) => {
                    let disabled = false;
                    if (field.id === 'phone') {
                        disabled = isPhoneConfirmed;
                    }
                    return (
                        <Input
                            disabled={disabled}
                            containerClassNames={'mb-4 w-full'}
                            inputClassNames={classes.input}
                            key={field.id}
                            label={field.label}
                            placeHolder={field.label}
                            onBlur={() => {
                                setData((prevState) => {
                                    return {
                                        ...prevState,
                                        values: {
                                            ...prevState.values,
                                            [field.id]: prevState.values[field.id].trim(),
                                        },
                                    };
                                });
                                onBlurHandler(field.id);
                            }}
                            name={field.name}
                            id={field.id}
                            mask={field.mask}
                            status={data.errors[field.id] ? 'error' : 'correct'}
                            extraInfo={data.errors[field.id] ?? ''}
                            value={data.values[field.id]}
                            onChange={(event) => onChangeData(event, field.id)}
                        />
                    );
                })}
            </div>
            <div className={'flex justify-end mt-6 msm:flex-col-reverse'}>
                <Button className={'mr-4 msm:mr-0 msm:mt-4'} onClickHandler={onPrevClickHandler}>
                    <span className={'text-mainColor text-[20px]'}>Назад</span>
                </Button>
                <Button
                    disabled={isDisabled}
                    type={'primary'}
                    onClickHandler={onSubmit}
                    className={classes.button}>
                    <span className={'text-white text-[20px]'}>{nextButtonText}</span>
                    {isLoading ? <Loader color={'white'} classes={classes.loader} /> : null}
                </Button>
            </div>
        </div>
    );
};
export default ClientData;
